import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    MessageCircle,
    Clock,
    Shield,
    HelpCircle,
    Sparkles,
    CheckCircle,
    ChevronRight,
    GraduationCap
} from 'lucide-react';

const Home = ({ signIn, user }) => {
    const navigate = useNavigate();
    return (
        <div className="min-h-screen bg-white">
            {/* Navigation Header */}
            <header className="fixed top-0 left-0 right-0 z-50">
                <div className="backdrop-blur-md bg-white/70 border-b border-gray-200/50">
                    <nav className="max-w-7xl mx-auto px-6 h-16 flex items-center justify-end gap-6">
                        <button
                            className="text-gray-500 hover:text-gray-900 transition-colors px-4 py-2 text-sm"
                        >
                            Demo
                        </button>
                        {user ? (
                            <Link 
                                to="/chat"
                                onClick={(e) => {
                                    e.preventDefault();
                                    console.log('Attempting to navigate to chat');
                                    try {
                                        window.location.href = '/chat';
                                    } catch (error) {
                                        console.error('Navigation error:', error);
                                    }
                                }}
                            >
                                <button
                                    className="text-gray-500 hover:text-gray-900 transition-colors px-4 py-2 text-sm"
                                >
                                    Mi cuenta
                                </button>
                            </Link>
                        ) : (
                            <button
                                onClick={signIn}
                                className="bg-gray-900 text-white px-4 py-2 rounded-full text-sm hover:bg-gray-800 transition-colors"
                            >
                                Iniciar sesión
                            </button>
                        )}
                    </nav>
                </div>
            </header>

            {/* Adjust top padding to account for fixed header */}
            <div className="min-h-screen bg-white pt-16">
                {/* Hero Section */}
                <header className="pt-32 pb-16 px-6">
                    <div className="max-w-6xl mx-auto">
                        <div className="flex flex-col lg:flex-row items-center gap-16">
                            <div className="flex-1 space-y-8 text-center lg:text-left">
                                <h1 className="text-5xl lg:text-7xl font-bold text-gray-900 tracking-tight">
                                    Gen-AI al servicio
                                    <span className="block bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
                                        de la justicia
                                    </span>
                                </h1>
                                <p className="text-xl text-gray-600 leading-relaxed max-w-2xl mx-auto lg:mx-0">
                                    Atena brinda acceso a información jurídica del Perú en tiempo real usando inteligencia artificial generativa
                                </p>
                                <div className="flex flex-col sm:flex-row gap-4 justify-center lg:justify-start">
                                    <Link to={user ? "/chat" : "#"} onClick={!user ? signIn : null}>
                                        <button className="w-full sm:w-auto px-8 py-4 bg-gray-900 text-white rounded-2xl font-medium hover:bg-gray-800 transition-all shadow-lg hover:shadow-xl">
                                            Empezar ahora
                                        </button>
                                    </Link>
                                    <button className="w-full sm:w-auto px-8 py-4 bg-gray-100 text-gray-900 rounded-2xl font-medium hover:bg-gray-200 transition-all">
                                        Ver demo
                                    </button>
                                </div>
                            </div>
                            <div className="flex-1">
                                <div className="relative">
                                    <div className="absolute -inset-1 bg-gradient-to-r from-blue-600 to-purple-600 rounded-3xl blur opacity-30"></div>
                                    <img
                                        src="https://maresone.s3.us-east-1.amazonaws.com/option.png"
                                        alt="Atena AI Chat"
                                        className="relative rounded-3xl shadow-2xl w-full object-cover"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                {/* Features Section */}
                <section className="py-16 bg-white">
                    <div className="max-w-7xl mx-auto px-6">
                        <div className="text-center space-y-4 mb-12">
                            <h2 className="text-4xl font-semibold text-gray-900">
                                Documentación legal en segundos
                            </h2>
                            <p className="text-xl text-gray-500">
                                Consulta instantánea de leyes peruanas
                            </p>
                        </div>

                        <div className="relative mb-16">
                            <div className="absolute -inset-1 bg-gradient-to-r from-gray-200 to-gray-100 rounded-3xl blur-lg"></div>
                            <img
                                src="https://maresone.s3.us-east-1.amazonaws.com/screenone.png"
                                alt="Atena AI Interface"
                                className="relative w-full h-auto rounded-3xl shadow-lg"
                            />
                        </div>

                        <div className="grid md:grid-cols-3 gap-6">
                            {[
                                {
                                    icon: <MessageCircle className="w-6 h-6" />,
                                    title: "Acceso Gratuito",
                                    description: "Utiliza Atena de forma gratuita para resolver tus dudas legales sin costo alguno."
                                },
                                {
                                    icon: <Clock className="w-6 h-6" />,
                                    title: "Respuestas Inmediatas",
                                    description: "Obtén respuestas al instante sin largas esperas para citas o consultas."
                                },
                                {
                                    icon: <Shield className="w-6 h-6" />,
                                    title: "Fuentes Confiables",
                                    description: "Nuestra IA utiliza RAG para proporcionarte las fuentes de información precisas y confiables."
                                }
                            ].map((feature, index) => (
                                <div
                                    key={index}
                                    className="group px-6 py-8 rounded-3xl hover:bg-gray-50 transition-all duration-300"
                                >
                                    <div className="w-12 h-12 rounded-full bg-gradient-to-r from-gray-100 to-white flex items-center justify-center mb-4 shadow-sm">
                                        <div className="text-gray-600">
                                            {feature.icon}
                                        </div>
                                    </div>
                                    <h3 className="text-lg font-semibold text-gray-900 mb-2">{feature.title}</h3>
                                    <p className="text-gray-500 leading-relaxed">{feature.description}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                {/* How It Works */}
                <section className="py-16 bg-gray-50">
                    <div className="max-w-7xl mx-auto px-6">
                        <div className="text-center space-y-4 mb-12">
                            <h2 className="text-4xl font-semibold text-gray-900">
                                Cómo Funciona
                            </h2>
                            <p className="text-xl text-gray-500">
                                Simple, rápido y eficiente
                            </p>
                        </div>

                        <div className="grid lg:grid-cols-2 gap-12 items-center mb-16">
                            <div className="space-y-8">
                                {[
                                    {
                                        icon: <HelpCircle className="w-6 h-6" />,
                                        title: "1. Haz tu Pregunta",
                                        description: "Escribe tu duda o consulta legal en nuestro chat."
                                    },
                                    {
                                        icon: <Sparkles className="w-6 h-6" />,
                                        title: "2. Procesamos tu Consulta",
                                        description: "Nuestra IA analiza tu pregunta utilizando inteligencia artificial y RAG."
                                    },
                                    {
                                        icon: <CheckCircle className="w-6 h-6" />,
                                        title: "3. Recibe Respuesta y Fuentes",
                                        description: "Obtén una respuesta detallada junto con las fuentes de información."
                                    }
                                ].map((step, index) => (
                                    <div
                                        key={index}
                                        className="flex items-start space-x-4 p-6 rounded-2xl bg-white shadow-sm hover:shadow-md transition-all duration-300"
                                    >
                                        <div className="flex-shrink-0">
                                            <div className="w-10 h-10 rounded-full bg-gradient-to-r from-gray-100 to-white flex items-center justify-center">
                                                <div className="text-gray-600">
                                                    {step.icon}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <h3 className="text-lg font-semibold text-gray-900 mb-1">{step.title}</h3>
                                            <p className="text-gray-500">{step.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="relative">
                                <div className="absolute -inset-1 bg-gradient-to-r from-gray-200 to-gray-100 rounded-3xl blur-lg"></div>
                                <img
                                    src="https://maresone.s3.us-east-1.amazonaws.com/option.png"
                                    alt="Atena Process"
                                    className="relative rounded-3xl shadow-lg w-full"
                                />
                            </div>
                        </div>
                    </div>
                </section>

                {/* CTA Section */}
                <section className="py-32 bg-gradient-to-b from-gray-50 to-white">
                    <div className="max-w-4xl mx-auto px-6 text-center">
                        <div className="mb-8 bg-gradient-to-b from-gray-100 to-white p-4 rounded-full inline-block">
                            <GraduationCap className="w-16 h-16 text-gray-900" />
                        </div>
                        <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text text-transparent">
                            Conoce tus Derechos en el Perú
                        </h2>
                        <p className="text-gray-500 mb-12 text-xl leading-relaxed max-w-2xl mx-auto">
                            Únete a la comunidad legal más grande del Perú. Resuelve tus consultas sobre leyes peruanas al instante con nuestra IA especializada.
                        </p>
                        <Link to={user ? "/chat" : "#"} onClick={!user ? signIn : null}>
                            <button className="px-12 py-4 bg-gray-900 text-white rounded-full font-medium hover:bg-gray-800 transition-all shadow-sm hover:shadow-md">
                                Empieza tu Consulta Legal
                            </button>
                        </Link>
                    </div>
                </section>

                {/* Footer */}
                <footer className="py-12 bg-gray-50 text-gray-500 border-t border-gray-200">
                    <div className="max-w-6xl mx-auto px-6">
                        <div className="flex flex-col md:flex-row justify-between items-center gap-6">
                            <div>
                                <p>Atena © {new Date().getFullYear()}. Todos los derechos reservados.</p>
                            </div>
                            <div className="flex gap-8">
                                <a href="/privacy" className="hover:text-gray-900 transition-colors">
                                    Política de Privacidad
                                </a>
                                <a href="/terms" className="hover:text-gray-900 transition-colors">
                                    Términos de Uso
                                </a>
                                <a href="/contact" className="hover:text-gray-900 transition-colors">
                                    Contacto
                                </a>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    );
};

export default Home;